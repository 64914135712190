import { useRouter } from 'next/router';
import Head from 'next/head';
import Script from 'next/script';
import { Fragment } from 'react';

export const PageHead = ({ title, description, image, acceptsCookies }) => {
  const router = useRouter();
  const url = `${process.env.NEXT_PUBLIC_ROOT_URL}${router.asPath}`;

  const titleToUse = title || 'Groundwork';
  const imageToUse = image?.url
    ? `${image.url}&fit=crop&crop=faces&w=1200&h=1200`
    : null;

  return (
    <Fragment>
      <Head>
        <title>{titleToUse}</title>
        <meta name="title" content={titleToUse} />
        {description && <meta name="description" content={description} />}
        <meta property="og:title" content={titleToUse} />
        {description && (
          <meta property="og:description" content={description} />
        )}
        <meta property="og:type" content="website" />
        {imageToUse && <meta property="og:image" content={imageToUse} />}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:creator" content="@eightmedia" />
        <meta property="twitter:title" content={titleToUse} />
        {description && (
          <meta property="twitter:description" content={description} />
        )}
        {imageToUse && <meta property="twitter:image" content={imageToUse} />}
        <link rel="canonical" href={url} />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/favicons/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicons/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicons/favicon-16x16.png"
        />
        <link rel="manifest" href="/favicons/site.webmanifest" />
        <link
          rel="mask-icon"
          href="/favicons/safari-pinned-tab.svg"
          color="#39b54a"
        />
        <link rel="shortcut icon" href="/favicons/favicon.ico" />

        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta
          name="msapplication-config"
          content="/favicons/browserconfig.xml"
        />
        <meta name="theme-color" content="#ffffff" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Head>
    </Fragment>
  );
};
