import { CYAN, GREEN } from 'shared/variables';
import { Footer } from 'components/Footer';
import { Link } from 'components/Link';
import { MessageBar } from 'components/MessageBar';
import { Nav } from 'components/Nav';
import { PageHead } from 'components/PageHead';
import { useAppContext } from 'shared/useAppContext';
import cx from 'classnames';
import styles from './Page.module.css';
import { grantConsentForEverything } from 'shared/tracking/utils';
import CookieConsent from 'react-cookie-consent';
import { trackingConfig } from 'shared/tracking/config';

export const Page = ({
  showCookieBar = true,
  children,
  color,
  description,
  preview,
  image,
  title,
  withLightFooter,
}) => {
  const { footerNavigation } = useAppContext();

  const { privacy_link } = footerNavigation.data;

  return (
    <div
      className={cx(styles.container, {
        [styles.green]: color === GREEN,
        [styles.cyan]: color === CYAN,
      })}
    >
      <PageHead title={title} description={description} image={image} />
      <Nav />
      <div className={styles.children}>{children}</div>
      <Footer isLight={withLightFooter} footerNavigation={footerNavigation} />
      {(showCookieBar || preview) && (
        <div className={styles.messageBarContainer}>
          {showCookieBar && <CookieBar privacy_link={privacy_link} />}
          {preview && <PreviewBar />}
        </div>
      )}
    </div>
  );
};

import messageBarStyles from './MessageBar.module.css';
import buttonStyles from './Button.module.css';

const CookieBar = ({ privacy_link }) => (
  <CookieConsent
    disableStyles={true}
    cookieName={trackingConfig.cookieBannerCookieName}
    cookieValue={{
      analytics: true,
      marketing: true,
    }}
    buttonText="Akkoord"
    onAccept={grantConsentForEverything}
    location="bottom"
    containerClasses={messageBarStyles.container}
    contentClasses={messageBarStyles.content}
    buttonClasses={cx(
      buttonStyles.button,
      buttonStyles.green,
      buttonStyles.small,
    )}
    buttonWrapperClasses={messageBarStyles.buttonContainer}
  >
    <div className={messageBarStyles.content}>
      <div className={messageBarStyles.emoji}>🍪</div>
      <div className={messageBarStyles.text}>
        Groundwork maakt gebruik van cookies om jou een optimale
        bezoekerservaring te bieden en jouw surfgedrag te meten.{' '}
        <Link href="/cookiesettings">Pas je voorkeuren aan</Link> of lees onze{' '}
        <Link field={privacy_link}>privacyverklaring</Link>.
      </div>
    </div>
  </CookieConsent>
);

const PreviewBar = () => (
  <MessageBar
    buttonLabel="Preview sluiten"
    emoji="🚨"
    onButtonClick={() => {
      window.location.href = '/api/preview-exit';
    }}
    text="Je bekijkt een preview. Dit is niet hoe bezoekers de website zien."
  />
);
